import { Ceremony } from 'api/portal/ceremonies/fetchCeremonies.types';

export interface CreateSectionVirtualGraduationViewModel {
  virtualGraduationMessage: string;
  virtualGraduationVideo?: string;
}

export interface SectionVirtualGraduationViewModel extends CreateSectionVirtualGraduationViewModel {
  id: string;
}
export type AnnouncementCategory = 'green' | 'orange' | 'red';

export interface CreatePortalViewModel {
  clientId: string;
  domain: string;
  alternativeDomains: string[];
  color?: string;
  secondaryColor: string;
  socialMessagingText?: string;
  socialMediaMessage?: string;
  logoBackgroundColor: string;
  virtualGraduationSectionToggle?: boolean;
  sectionVirtualGraduation?: SectionVirtualGraduationViewModel;
  clipSearchEnabled?: boolean;
  gaTrackingId: string;
  customContentSectionToggle?: boolean;
  customContentTitle?: string;
  customContentText?: string;
  customContentBackgroundColor?: string;
  customContentBackgroundImageUrl?: string;
  announcementSectionToggle?: boolean;
  announcementCategory?: AnnouncementCategory;
  announcementMessage?: string;
  robotsIndexingToggle?: boolean;
}

export type SponsorBanner = {
  src: string;
  alt: string;
  uri: string;
};

export interface PortalViewModel extends CreatePortalViewModel {
  id: string;
  clientName: string;
}

export interface PortalNextJsModel extends PortalAddToAnyButtons {
  id: string;
  clientName: string;
  clientId: string;
  domain: string;
  logoBackgroundColor: string;
  gaTrackingId: string;
  logoImageUrl?: string;
  color?: string;
  robotsIndexingToggle?: boolean;
  socialMediaMessage?: string;
  virtualGraduationSectionToggle?: boolean;
  sectionVirtualGraduation?: SectionVirtualGraduationViewModel;
  institutionId: string | null;
  institutionName: string | null;
  accountId: string | null;
  accountName: string | null;
  clipSearchEnabled: boolean;
  ceremonies?: Ceremony[];
  sponsorBanners: SponsorBanner[];
  displayBannersOnCeremonySite: boolean;
  displayPlantTreeBanner: boolean;
  eventId?: string;
  seasonId?: string;
}

export interface IsMainUriModel {
  isMainUri: boolean;
  mainUri: string;
}

export interface PortalConfigurationViewModel extends PortalAddToAnyButtons, PortalNextJsModel, IsMainUriModel {}

export type SearchResultsParticipant = {
  fullName: string;
  subtitle: string;
  secondSubtitle: string;
  clipPageUri: string;
  clipSearchEnabled: boolean;
};

export type SearchResultsCeremonies = {
  fullName: string;
  date: string;
  clipPageUri: string;
};

export type SearchResults = {
  participants: SearchResultsParticipant[];
  ceremonies: SearchResultsCeremonies[];
};

export interface ParticipantSearchViewModel {
  fullName: string;
  subtitle?: string;
  thumbnailUri?: string;
  clipGifUri?: string;
  clipPageUri?: string;
}

export interface SortedClip {
  fullName: string;
  subtitle?: string;
  secondSubtitle?: string;
  thumbnailUri?: string | null;
  clipGifUri?: string | null;
  clipPageUri?: string;
}

export interface ParticipantClipInfoNextJsModel {
  id: string;
  fullName: string;
  subtitle: string;
  color?: string;
  clientName?: string;
  customContentTitle?: string;
  customContentText?: string;
  eventId?: string;
  seasonId?: string;
  clip: {
    thumbnail?: string;
    gif?: string;
    uri?: string;
    width?: number;
    height?: number;
  };
  config: {
    isAllowToPurchaseClip: boolean;
    clipPurchaseType: ClipPurchaseType | null;
    currencyCode: string | null;
    pricePerClip: number | null;
    orderState: OrderState | null;
    clientType: SalesForceClientType | null;
  };
  hashtag?: string;
  isViewClip?: boolean;
  reactionInfo: {
    likes: number;
    hearts: number;
  };
  isAllowDonation?: string;
  instituitionId?: string;
  isAllowCollctiv: boolean;
  isAllowForAmazonMusic: boolean;
  isSignUpToAmazonMusic: boolean;
  isAllowForDownloadClip: boolean;
}

export interface ParticipantClipUrlNextJsModel {
  id: string;
  fullName: string;
  clipSiteUrl: string;
}

export enum OrderState {
  IsPreOrder = 'isPreOrder',
  IsPaid = 'isPaid',
  IsVoucher = 'isVoucher',
}

export enum SalesForceClientType {
  HerffJonesCollege = 'HerffJonesCollege',
  HerffJonesHighSchool = 'HerffJonesHighSchool',
  HerffJonesSchoolDistrict = 'HerffJonesSchoolDistrict',
  SingleInstitution = 'SingleInstitution',
  InstitutionGroup = 'InstitutionGroup',
}

export enum ClipPurchaseType {
  None = 'None',
  WholesaleClientPays = 'WholesaleClientPays',
  WholesaleBDRClientPays = 'WholesaleBDRClientPays',
  RetailEndUserPays = 'RetailEndUserPays',
  PartnerNetworkHerffJones = 'PartnerNetworkHerffJones',
  PartnerNetworkLifeTouch = 'PartnerNetworkLifeTouch',
  PartnerNetworkReedGraduations = 'PartnerNetworkReedGraduations',
  UnknownAtThisTime = 'UnknownAtThisTime',
  PartnerNetworkFSM = 'PartnerNetworkFSM',
  PartnerNetworkTempest = 'PartnerNetworkTempest',
}

export interface CeremonyClipInfoNextJsModel {
  fullName: string;
  date: string;
  clientName?: string;
  youtubeVideoId: string;
  youtubeChildrenSafe: boolean;
  color?: string;
  customContentTitle?: string;
  institutionName?: string;
}

export interface CreateAddToAnyViewModel {
  socialIdentifier: string;
  displayName: string;
  image: string;
  color: string;
  order: number;
}

export interface AddToAnyViewModel extends CreateAddToAnyViewModel {
  id: string;
}

export interface PortalAddToAnyButtons {
  addToAnyButtons: AddToAnyViewModel[];
}

export interface PortalClipsViewModel {
  clips: string[];
}

export interface SurveyViewModel {
  id: string;
  surveyData?: JSON;
  clipThumbnail?: string;
  surveySkip: boolean;
}
